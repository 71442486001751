import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from '../../Header/index';
import Footer from '../../footer';
import Layout from '../../layout';
import Seo from '../../seo';
import { getPreviewPage } from '../../../state/app';
import { RichText } from '../../richTextOptions';
import { ContentfulBlockImage } from '../../BlockLibrary/ContentfulBlockImage';

const PreviewPgArticle = ({ article, isLoading, pgSidebarImage }) => {
     const seoTitleTag = article.fields.seoTitleTag ? article.fields.seoTitleTag : `${article.fields.sidebarTitle} | Pet Insurance Benefits for Employees`;
     const seoMetaDescription = article.fields.seoMetaDescription ? article.fields.seoMetaDescription : article.fields.shortDescription;

     return (
          <>
               <Layout>
                    <Seo
                         title={seoTitleTag}
                         canonical={article.fields.seoCanonicalUrl}
                         description={seoMetaDescription}
                         robots={article.fields.robotsDirective}
                         image={article.fields.socialImage ? article.fields.socialImage : article.fields.featuredImage}
                    />
                    {
                         isLoading && (
                              <div className="page-loading">Loading ...</div>
                         )
                    }
                    <Header specificPhone={article.fields.specificPhone} header={article.fields.header} tabInd={1}></Header>
                    <section data-tabind={2} className={`pg-article-content section-tabIndex-2`}>
                         <div className="container">
                              <div className="row">
                                   <div className="col-lg-9">
                                        <div className="pg-article-wrapper">
                                             <div className="pg-title-social">
                                                  {article.fields.articleName && <h1>{article.fields.articleName}</h1>}
                                                  <div className="social">
                                                       <div className="addthis_toolbox">
                                                            <div className="custom_images">
                                                                 <a className="addthis_button_linkedin animation">
                                                                      <img loading="lazy" src="/images/pet-gazette/linkedin.png" alt="Share to Linkedin" />
                                                                 </a>
                                                                 <a className="addthis_button_facebook animation">
                                                                      <img loading="lazy" src="/images/pet-gazette/facebook.png" alt="Share to Facebook" />
                                                                 </a>
                                                                 <a className="addthis_button_twitter animation">
                                                                      <img loading="lazy" src="/images/pet-gazette/twitter.png" alt="Share to Twitter" />
                                                                 </a>
                                                                 <a className="addthis_button_more">
                                                                      <img loading="lazy" src="/images/pet-gazette/youtube.png" alt="More..." />
                                                                 </a>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             {article.fields.body && <RichText data={article.fields.body}></RichText>}
                                        </div>
                                   </div>
                                   <div className="col-lg-3 sidebar-content">
                                        <div className="image-layout">
                                             <ContentfulBlockImage {...pgSidebarImage} />
                                             <a href="/veterinarians/"><img loading="lazy" src="/images/hrpg.jpg" alt="Add pet insurance to your voluntary benefit package" /></a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </section>
                    <Footer footer={article.fields.footer} tabInd={3}></Footer>
               </Layout>
          </>
     )
}

PreviewPgArticle.propTypes = {
     article: PropTypes.object,
}

function mapStateToProps(state) {
     return {
          article: getPreviewPage(state)
     };
}

export default connect(mapStateToProps)(PreviewPgArticle);
